import React, { Component } from 'react';
import axios from "axios";

import { IoMdLogIn } from 'react-icons/io';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: '',
            companyId: '',
            message: '',
            step: 0,
        };

        this.handleSubmit       = this.handleSubmit.bind(this);
        this.handleInputChange  = this.handleInputChange.bind(this);

    }

    componentDidMount() {
        document.getElementById("app-window").focus();

    }

    handleInputChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({ [itemName]: itemValue});
    }

    handleSubmit(e) {

        var myDiv = document.getElementById('app-window');
        myDiv.scrollTop = 0;
        this.setState( { message: '' });
        this.setState( { step: 1 });

        e.preventDefault();

        var data                = {};

        data.eventId            = this.state.eventId;
        data.companyId          = this.state.companyId;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=resetPassword',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            this.props.connectionError(false);
            this.setState({ message: responseObject.data.message });

            if(!responseObject.data.success) {
                this.setState( { step: 0 });
            }


        }).catch((error) => {

            console.log(error);
            this.props.connectionError(true);

        });

    }

    goBack(){
        this.props.changeScreen('setup', '');
    }



    render() {

        const {companyId, eventId, colors, logoTop, logoBottom } = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const messageStyle = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap",
            border: '1px solid ' + colors.color_1,
            borderRadius: '10px',
            padding: '10px'
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            cursor: 'pointer',
        }



        return (

            <form onSubmit={this.handleSubmit} className="form-post-password">
                <div className="container-fluid d-flex align-items-center vh-100">
                    <div className="row d-flex justify-content-center vh-100 vw-100">
                        <div className="content-wrapper page-home col-12 col col-sm-8 col-lg-6">

                            {logoTop !== '' &&
                            <div className="section-top-wrapper">
                                <img id="logo" src={logoTop} alt="Logo"/>
                            </div>
                            }

                            <div className="mt-3 mb-3" style={frontStyle}><strong>Forgot Password</strong></div>

                            <div className="content">

                                {this.state.message !== "" &&
                                    <div id="message" className="mb-3 text-center" style={messageStyle} dangerouslySetInnerHTML={{__html: this.state.message}}></div>
                                }

                                {this.state.step === 0 &&

                                    <div>
                                        <div className="mb-3">Please enter the fields below, and we will send you a one-time
                                            login to the email we have registered.
                                        </div>

                                        {eventId === '' &&
                                        <div>
                                            <div className="mb-1" style={frontStyle}>Event Id</div>
                                            <div className="mb-2">
                                                <input
                                                    className={"form-control pl-3"}
                                                    type="text"
                                                    id="eventId"
                                                    name="eventId"
                                                    placeholder="Event Id"
                                                    defaultValue={eventId}
                                                    onChange={this.handleInputChange}
                                                    />
                                            </div>
                                        </div>
                                        }

                                        <div className="mb-1" style={frontStyle}>Company Id</div>
                                        <div className="mb-2">
                                            <input
                                                className={"form-control pl-3"}
                                                type="text"
                                                id="companyId"
                                                name="companyId"
                                                placeholder="Company Id"
                                                defaultValue={companyId}
                                                onChange={this.handleInputChange}
                                                />
                                        </div>
                                    </div>
                                }

                                <div className="form-group text-right mt-4 mb-4">
                                    {this.state.step === 0 &&
                                        <button className="btn-medium btn-white" type="submit" style={btnStyle}>
                                            Send login <IoMdLogIn/>
                                        </button>
                                    }

                                    <div id="go-back" style={frontStyle} onClick={() => this.goBack()}>
                                        Back <IoMdLogIn/>
                                    </div>
                                </div>



                           </div>

                            {logoBottom !== '' &&
                            <div className="section-bottom-wrapper mt-5">
                                <div className="text-center">
                                    <img id="logo-bottom" src={logoBottom} alt="Logo"/>
                                </div>
                            </div>
                            }

                       </div>
                   </div>
               </div>
            </form>
        );
    }
}

export default ResetPassword;