import React, { Component } from 'react';
import axios from 'axios';

import { IoIosSettings } from 'react-icons/io';

class Upload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            step: 0,
            selectedFiles: [],
        };

        this.handleFileChange = this.handleFileChange.bind(this);

    }

    componentDidMount() {
        document.getElementById("app-window").focus();
    }

    handleFileChange(e) {

        this.setState({ message: '' });

        const files = e.target.files;
        if (!files || files.length === 0) {
            return;
        }

        this.setState({ selectedFiles: files });

    };

    uploadFiles() {

        if (!this.state.selectedFiles || this.state.selectedFiles.length === 0) {
            this.setState({ message: 'Please select at least one file.' });
            return;
        }

        this.setState({ message: 'Uploading files...' });

        const formData = new FormData();

        formData.append('token', this.props.token);
        formData.append('event_id', this.props.eventId);

        for (let i = 0; i < this.state.selectedFiles.length; i++) {
            formData.append('files[]', this.state.selectedFiles[i]);
        }

        this.setState({ step: 1 });

        axios
            .post(this.props.domain + '/core/upload.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((responseObject) => {

                this.props.connectionError(false);

                console.log(responseObject);

                if(responseObject.data.valid_token === true) {

                    // Reset selectedFiles to an empty array after successful upload
                    this.setState({
                        selectedFiles: [],
                    });

                    this.setState({ step: 0 }, () => {
                        // Reset the file input by cloning and replacing it
                        const input = document.querySelector('input[type="file"]');
                        input.value = ''; // Clearing the value directly
                        this.setState({ message: 'Files uploaded successfully!' });
                    });

                } else {

                    var prop        = {};
                    prop.appMessage = responseObject.data.result;
                    this.props.changeScreen('setup', prop);

                }

            })
            .catch((error) => {
                this.props.connectionError(true);
                console.error('Error sending data:', error);
                this.setState({ message: 'Error uploading files. Please try again.' });
                this.setState({ step: 0 });
            });

    };



    render() {

        const {companyName, initials, colors, logoTop, logoBottom } = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const userStyle    = {
            color: colors.color_1 + 'BB'
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            cursor: 'pointer',
        }

        const borderStyle = {
            color: colors.color_1,
            border: '1px solid ' + colors.color_1,
        };

        return (

            <div className="container-fluid d-flex align-items-center vh-100">
                <div className="row d-flex justify-content-center vh-100 vw-100">
                    <div className="content-wrapper page-home col-12 col col-sm-8 col-lg-6">

                        {logoTop !== '' &&
                        <div className="section-top-wrapper">
                            <img id="logo" src={logoTop} alt="Logo"/>
                        </div>
                        }

                        <div className="mt-3 mb-3" style={frontStyle}><strong>Upload QR-Codes</strong></div>

                        <div className="content">

                            {this.state.message !== "" &&
                            <div id="message" className="mb-3 text-center" style={frontStyle} dangerouslySetInnerHTML={{__html: this.state.message}}></div>
                            }

                            <div className="form-group text-center mb-4">
                                <button className="btn-medium m-1" type="button" style={btnStyle} onClick={() => this.props.changeScreen('admin', '')}>
                                    Back <IoIosSettings/>
                                </button>

                            </div>

                            { this.state.step === 0 &&

                            <div id="upload-wrapper" style={borderStyle}>
                                <input
                                    type="file"
                                    onChange={this.handleFileChange}
                                    multiple // Enable multiple file selection
                                    accept=".jpg, .jpeg, .png"
                                    />
                                <button className="btn-medium mt-3" style={btnStyle} onClick={() => this.uploadFiles()}>Upload Files</button>
                            </div>

                            }

                        </div>

                        <div className="section-bottom-wrapper mt-3">
                            <div className="form-group text-center" style={userStyle}>

                                <div id="user">
                                    <div>SIGNED IN AS</div>
                                    <div>Company: {companyName}</div>

                                    {initials !== '' &&
                                    <div>Initials: {initials}</div>
                                    }
                                </div>

                            </div>

                            {logoBottom !== '' &&
                            <div className="section-bottom-wrapper mt-5">
                                <div className="text-center">
                                    <img id="logo-bottom" src={logoBottom} alt="Logo"/>
                                </div>
                            </div>
                            }
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}

export default Upload;