import React, { Component } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';

import { IoMdLogIn } from 'react-icons/io';
import { IoIosHelpCircle } from 'react-icons/io';
import { IoIosWarning } from "react-icons/io";

class Setup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: '',
            companyId: '',
            email: '',
            companyGroupId: '',
            companyPassword: '',
            passwordInfo: false,
            message: '',
            isValidEventId: true,
            isValidCompanyId: true,
            isValidPassword: true,
            isValidEmail: true,
            warning: false,
            pageReady: false,
        };

        this.handleSubmit       = this.handleSubmit.bind(this);
        this.handleInputChange  = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        document.getElementById("app-window").focus();

        this.setState({ eventId: this.props.eventId});
        this.setState({ companyId: this.props.companyId});
        this.setState({ email: this.props.email});

        // To get the value of a cookie named 'authToken'
        const authToken = Cookies.get('authToken');

        if(authToken && authToken !== 'undefined' && authToken !== 'null') {
            this.handleTokenLogin(authToken);
        } else {
            this.setState({ pageReady: true });
        }

    }

    handleInputChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({ [itemName]: itemValue});
    }

    handleSubmit(e) {

        e.preventDefault();

        var myDiv = document.getElementById('app-window');
        myDiv.scrollTop = 0;
        this.setState({ message: ''});
        this.setState({ warning: false });

        var errors = false;

        if (this.state.eventId.trim() === '') {
            errors = true;
            this.setState({ isValidEventId: false });
        } else {
            this.setState({ isValidEventId: true });
        }

        if (this.state.companyId.trim() === '') {
            errors = true;
            this.setState({ isValidCompanyId: false });
        } else {
            this.setState({ isValidCompanyId: true });
        }

        if (this.state.companyPassword.trim() === '') {
            errors = true;
            this.setState({ isValidPassword: false });
        } else {
            this.setState({ isValidPassword: true });
        }

        if (this.state.email.trim() === '') {
            errors = true;
            this.setState({ isValiEmails: false });
        } else {

            if (this.validateEmail(this.state.email)) {
                this.setState({isValidEmail: true});
            } else {
                errors = true;
                this.setState({ isValidEmail: false });
            }

        }

        if(!errors) {

            var data                = {};

            data.eventId            = this.state.eventId;
            data.companyId          = this.state.companyId;
            data.companyPassword    = this.state.companyPassword;
            data.email              = this.state.email;

            axios({
                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                url: this.props.domain + '/core/connect.php?action=signIn',
                data: JSON.stringify(data)

            }).then((responseObject) => {

                this.props.connectionError(false);
                this.handleLogin(responseObject)

            }).catch((error) => {

                console.log(error);
                this.props.connectionError(true);

            });

        } else {

            this.setState({ warning: true });

            if(this.validateEmail(this.state.email)) {
                this.setState({ message: 'Please fill in the required fields' });
            } else {
                this.setState({ message: 'Please fill in the required fields and check that email is formated correct' });
            }


        }

    }

    handleTokenLogin(token) {

        var data    = {};
        data.token  = token;

        axios({
            method: 'post',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            url: this.props.domain + '/core/connect.php?action=signInToken',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            this.props.connectionError(false);

            if (responseObject.data.hasOwnProperty('token') && responseObject.data.token !== null) {

                Cookies.set('authToken', responseObject.data.token, { expires: 1, secure: true, sameSite: 'strict' }); // Expires in 1 days, adjust as needed

                this.setState({ eventId: responseObject.data.event_id });
                this.setState({ companyId: responseObject.data.company_id });
                this.setState({ companyGroupId: responseObject.data.company_group_id });
                this.setState({ email: responseObject.data.email });
                this.handleLogin(responseObject);
            }

            this.setState({ pageReady: true });

        }).catch((error) => {

            console.log(error);
            this.props.connectionError(false);

        });

    }

    handleLogin(responseObject) {

        Cookies.set('authToken', responseObject.data.token, { expires: 1, secure: true, sameSite: 'strict' }); // Expires in 1 days, adjust as needed

        if (responseObject.data.companyAppLicensesUsed) {

            this.props.changeScreen('removeusers', responseObject.data);

        } else if (responseObject.data.event && responseObject.data.event !== null && responseObject.data.event.event_name !== 'Forbidden' && responseObject.data.hasOwnProperty('token') && responseObject.data.token !== null) {
            this.props.startScan(responseObject.data);

        } else if (responseObject.data.companyId === '1' && responseObject.data.hasOwnProperty('token') && responseObject.data.token !== null) {
            // ToDo: Kan isAdmin slettes når vi benytter company Id = 1 som admin?
            this.props.setAdmin(this.state.eventId, responseObject.data);

        } else {

            this.setState({ warning: true });

            if(this.props.eventId === '' && this.props.companyId === '') {
                this.setState({ message: '<strong>Company or Password not found!</strong>\n\n<i>Ensure that the entered Company Id is accurate for this Event and double-check the Password.</i>'})
            } else {
                this.setState({ message: '<strong>Company or Password not found!</strong>\n<i>Double-check the Password</i>'})
                this.setState({ isValidPassword: false });
            }

            console.log('no token');

        }

    }

    validateEmail(email){
        // Regular expression for email validation
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    resetPassword(){
        this.props.changeScreen('resetpassword', '');
    }

    togglePasswordInfo() {
        console.log(this.state.passwordInfo);
        if(this.state.passwordInfo === true) {
            this.setState({ passwordInfo: false});
        } else {
            this.setState({ passwordInfo: true});
        }
    }

    render() {

        const {eventId, companyId, companyName, email, colors, logoTop, logoBottom } = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            cursor: 'pointer',
        }

        const warningStyle    = {
            color: colors.color_3,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const messageStyle = this.state.warning ? warningStyle : frontStyle;

        return (

                <form onSubmit={this.handleSubmit} className="form-post-lead">
                     <div className="container-fluid d-flex align-items-center vh-100">
                        <div className="row d-flex justify-content-center vh-100 vw-100">
                            <div className="content-wrapper page-setup col-12 col col-sm-8 col-lg-6">
                                <div className="content">

                                    {logoTop !== '' &&
                                        <div className="section-top-wrapper">
                                            <img id="logo" src={logoTop} alt="Logo" />
                                        </div>
                                    }

                                    <div className="mt-3 mb-3" style={frontStyle}><strong>Setup</strong></div>

                                    {this.state.message !== "" &&
                                    <div className="mb-3" style={ messageStyle }>
                                        {this.state.warning &&
                                            <span className="warning"><IoIosWarning /></span>
                                        }
                                        <span id="message" dangerouslySetInnerHTML={{__html: this.state.message}}></span>
                                    </div>
                                    }

                                    {this.state.pageReady &&
                                    <div>
                                        {eventId === '' &&
                                        <div>
                                            <div className="mb-1" style={frontStyle}>Event Id</div>
                                            <div className="mb-2">
                                                <input
                                                    className       = {"form-control pl-3"}
                                                    type            = "text"
                                                    id              = "eventId"
                                                    name            = "eventId"
                                                    placeholder     = "Event Id"
                                                    defaultValue    = {eventId}
                                                    onChange        = {this.handleInputChange}
                                                    style           = {{ borderColor: this.state.isValidEventId ? '' : colors.color_3 }}
                                                    />
                                            </div>
                                        </div>
                                        }
                                        {companyId === '' &&
                                        <div>
                                            <div className="mb-1" style={frontStyle}>Company Id</div>
                                            <div>
                                                <input
                                                    className       = {"form-control pl-3"}
                                                    type            = "text"
                                                    id              = "companyId"
                                                    name            = "companyId"
                                                    placeholder     = "Company Id"
                                                    defaultValue    = {companyId}
                                                    onChange        = {this.handleInputChange}
                                                    style           = {{ borderColor: this.state.isValidCompanyId ? '' : colors.color_3 }}
                                                    />
                                            </div>
                                        </div>
                                        }

                                        {companyId !== '' &&
                                        <div id="company-name" style={frontStyle}><strong>{ companyName }</strong></div>
                                        }

                                        <div className="mb-1" style={frontStyle} onClick={() => this.togglePasswordInfo()}>Password <span id="forgot-password"><IoIosHelpCircle/></span></div>

                                        {this.state.passwordInfo &&
                                        <div className="pb-3" style={frontStyle}>Contact your coordinator to get the password</div>
                                        }

                                        <div className="mb-2">
                                            <input
                                                className           = {"form-control pl-3"}
                                                type                = "password"
                                                id                  = "companyPassword"
                                                name                = "companyPassword"
                                                placeholder         = "Your Password"
                                                defaultValue        = ""
                                                onChange            = {this.handleInputChange}
                                                style               = {{ borderColor: this.state.isValidPassword ? '' : colors.color_3 }}
                                                />
                                        </div>

                                        {email === '' &&
                                        <div>
                                            <div className="mb-1" style={frontStyle}>Email</div>
                                            <div className="mb-2">
                                                <input
                                                    className       = {"form-control pl-3"}
                                                    type            = "text"
                                                    id              = "email"
                                                    name            = "email"
                                                    placeholder     = "Your Email"
                                                    defaultValue    = {email}
                                                    onChange        = {this.handleInputChange}
                                                    style           = {{ borderColor: this.state.isValidEmail ? '' : colors.color_3 }}
                                                    />
                                            </div>
                                        </div>
                                        }

                                        {email !== '' &&
                                        <div className="mb-3" style={frontStyle}>Email: { email }</div>
                                        }

                                        <div className="form-group text-right mt-4 mb-4">
                                            <button className="btn-medium btn-white" type="submit" style={btnStyle}>
                                                Sign In <IoMdLogIn/>
                                            </button>
                                        </div>
                                    </div>
                                    }



                                </div>
                                {logoBottom !== '' &&
                                <div className="section-bottom-wrapper mt-5">
                                    <div className="text-center">
                                        <img id="logo-bottom" src={logoBottom} alt="Logo"/>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                </form>


        );
    }
}

export default Setup;