import React, { Component } from 'react';
import axios from "axios";

import { IoIosSettings } from 'react-icons/io';


class LogSystem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logSystem: [],
            logFilter: 'error',
            currentPage: 1, // Gem den aktuelle side i state
            logsPerPage: 25, // Antal logs per side
        };

        this.handleInputChange = this.handleInputChange.bind(this);

    }

    componentDidMount() {
        document.getElementById("app-window").focus();
        this.getLogSystem();
    }
    handleInputChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;
        this.setState({ [itemName]: itemValue});
    }


    // Metode til at ændre den aktuelle side
    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    getLogSystem() {

        var data            = {};

        data.token          = this.props.token;

        this.setState({message: 'Receiving Log'});

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=getLogSystem',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            if(responseObject.data.valid_token === true) {

                this.setState({ message: '' });
                this.setState({ logSystem: responseObject.data.log_system });
                this.props.connectionError(false);

            } else {
                var prop        = {};
                prop.appMessage = responseObject.data.result;
                this.props.changeScreen('setup', prop);

            }

        }).catch((error) => {

            console.log(error)
            this.props.connectionError(true);

        });

    }

    clearLogSystem() {

        const isConfirmed = window.confirm('Do you want to clear ALL logs?');

        if (isConfirmed) {

            var data = {};

            data.token = this.props.token;

            this.setState({message: 'Clear Log'});

            axios({
                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                url: this.props.domain + '/core/connect.php?action=clearLogSystem',
                data: JSON.stringify(data)

            }).then((responseObject) => {

                if (responseObject.data.valid_token === true) {

                    console.log(responseObject.data);

                    this.setState({message: ''});
                    this.setState({logSystem: responseObject.data.log_system});
                    this.props.connectionError(false);

                } else {
                    var prop = {};
                    prop.appMessage = responseObject.data.result;
                    this.props.changeScreen('setup', prop);

                }

            }).catch((error) => {

                console.log(error)
                this.props.connectionError(true);

            });
        }

    }

    render() {

        const {companyName, initials, colors, logoTop, logoBottom } = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const userStyle    = {
            color: colors.color_1 + 'BB'
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            cursor: 'pointer',
        }

        const systemLogStyle = {
            padding: '5px 8px',
            border: '1px solid' + colors.color_1 + 'BB',
            marginBottom: '5px',
            borderRadius: '10px'
        }

        const { logSystem, logFilter, currentPage, logsPerPage } = this.state;

        // Filtrér og beregn data til den aktuelle side
        const filteredLogs = logSystem.filter(
            (item) => item.name === logFilter || logFilter === ''
        );

        // Beregn start- og slutindekset baseret på den aktuelle side
        const indexOfLastLog = currentPage * logsPerPage;
        const indexOfFirstLog = indexOfLastLog - logsPerPage;
        const currentLogs = filteredLogs.slice(indexOfFirstLog, indexOfLastLog);

        // Beregn hvor mange sider der er brug for
        const totalPages = Math.ceil(filteredLogs.length / logsPerPage);

        return (

            <div className="container-fluid d-flex align-items-center vh-100">
                <div className="row d-flex justify-content-center vh-100 vw-100">
                    <div className="content-wrapper page-home col-12 col col-sm-8 col-lg-6">

                        {logoTop !== '' &&
                        <div className="section-top-wrapper">
                            <img id="logo" src={logoTop} alt="Logo"/>
                        </div>
                        }

                        <div className="mt-3 mb-3" style={frontStyle}><strong>System Log</strong></div>

                        <div className="content">

                            {this.state.message !== "" &&
                                <div id="message" className="mb-3 text-center" style={frontStyle}
                                     dangerouslySetInnerHTML={{__html: this.state.message}}></div>
                            }

                            <div className="form-group text-center mb-3">

                                <button className="btn-medium m-1" type="button" style={btnStyle}
                                        onClick={() => this.props.changeScreen('admin', '')}>
                                    Back <IoIosSettings/>
                                </button>

                                <button className="btn-medium m-1" type="button" style={btnStyle}
                                        onClick={() => this.clearLogSystem()}>
                                    Clear ALL Logs <IoIosSettings/>
                                </button>

                            </div>

                            <div className="text-center mb-3">
                                <select
                                    id="logFilter"
                                    name="logFilter"
                                    className="app-dropdown"
                                    value={this.state.logFilter}
                                    onChange={this.handleInputChange}
                                >
                                    <option value="">Show All</option>
                                    <option value="error">Errors</option>
                                    <option value="notification">Notifications</option>

                                </select>
                            </div>

                            <div>
                                {currentLogs.map((item, index) => (
                                    <div className="row-system-log" key={index} style={systemLogStyle}>
                                        <div>
                                            <span className="system-log-created">{item.created}</span>
                                            <span className="system-log-name">{item.name}</span>
                                        </div>
                                        <div className="log-system-data">{item.data}</div>
                                    </div>
                                ))}

                                {/* Pagination Controls */}
                                <div className="pagination-controls text-center mt-2">
                                    <button
                                        className="btn-small m-1"
                                        style={btnStyle}
                                        onClick={() => this.handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Prev
                                    </button>
                                    {[...Array(totalPages)].map((_, i) => (
                                        <button
                                            key={i}
                                            style={btnStyle}
                                            onClick={() => this.handlePageChange(i + 1)}
                                            className={currentPage === i + 1 ? 'btn-small active m-1' : 'btn-small m-1'}
                                        >
                                            {i + 1}
                                        </button>
                                    ))}
                                    <button
                                        className="btn-small m-1"
                                        style={btnStyle}
                                        onClick={() => this.handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>

                        </div>

                        <div className="section-bottom-wrapper mt-3">
                            <div className="form-group text-center" style={userStyle}>

                                <div id="user">
                                    <div>SIGNED IN AS</div>
                                    <div>Company: {companyName}</div>

                                    {initials !== '' &&
                                        <div>Initials: {initials}</div>
                                    }
                                </div>

                            </div>

                            {logoBottom !== '' &&
                                <div className="section-bottom-wrapper mt-5">
                                    <div className="text-center">
                                        <img id="logo-bottom" src={logoBottom} alt="Logo"/>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}

export default LogSystem;