import React, { Component } from 'react';
import axios from "axios";
import Cookies from 'js-cookie';

import { IoMdLogIn } from 'react-icons/io';
import { IoIosSettings } from 'react-icons/io';

class RemoveUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
        };

    }

    signOut(){

        this.setState({ message: 'Signing Out' });
        this.setState({ signedIn: false });

        var data          = {};
        data.token        = this.props.token;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=signOut',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            Cookies.remove('authToken');
            this.props.connectionError(false);
            this.props.changeScreen('setup', 'clearEvent');

        }).catch((error) => {

            this.props.connectionError(true);
            console.log(error);

        });

    }


    componentDidMount() {
        document.getElementById("app-window").focus();
    }

    render() {

        const {companyName, initials, colors, logoTop, logoBottom, activeUsersList, companyAppLicenses, token } = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            cursor: 'pointer',
        }

        const userStyle = {
            padding: '5px 8px',
            border: '1px solid' + colors.color_1 + 'BB',
            marginBottom: '5px',
            borderRadius: '10px',
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
        }

        return (

            <div className="container-fluid d-flex align-items-center vh-100">
                <div className="row d-flex justify-content-center vh-100 vw-100">
                    <div className="content-wrapper page-home col-12 col col-sm-8 col-lg-6">

                        {logoTop !== '' &&
                        <div className="section-top-wrapper">
                            <img id="logo" src={logoTop} alt="Logo"/>
                        </div>
                        }

                        <div className="mt-3 mb-3" style={frontStyle}><strong>Remove Users</strong></div>

                        <div className="content">

                            {this.state.message !== "" &&
                                <div id="message" className="mb-3 text-center" style={frontStyle} dangerouslySetInnerHTML={{__html: this.state.message}}></div>
                            }

                            <div className="form-group text-center mb-4">
                                    <button className="btn-medium m-1" type="button" style={btnStyle} onClick={() => this.props.changeScreen('setup', '')}>
                                        Back <IoIosSettings/>
                                    </button>
                            </div>

                            <div className="pb-2" style={frontStyle}>
                                <p>The amount of active users has exceeded the amount of licenses ({companyAppLicenses}).</p>
                                <p>To be able to log in, you must ask another user to log out.</p>
                                <p>If the other user has closed the Lead Scanner App, they can log in using the email that matches the initials.</p>
                            </div>

                            <div>
                                {activeUsersList.map((item, index) => (
                                    <div className="row-lead" key={index} style={userStyle}>
                                        <div className="login-created">Logged in: { item.created }</div>
                                        <div>Initials: { item.initials }</div>
                                    </div>

                                ))}
                            </div>

                       </div>

                       <div className="section-bottom-wrapper mt-3">

                           {token !== null &&

                               <div className="form-group text-center" style={frontStyle}>

                                   <div id="user">
                                       <div>SIGNED IN AS</div>
                                       <div>Company: {companyName}</div>

                                       {initials !== '' &&
                                       <div>Initials: {initials}</div>
                                       }
                                   </div>

                                   <div className="text-right m-2">
                                       <button className="btn-medium" type="button" style={btnStyle}
                                               onClick={() => this.signOut()}>
                                           Sign Out <IoMdLogIn/>
                                       </button>
                                   </div>

                               </div>
                           }

                           {logoBottom !== '' &&
                           <div className="section-bottom-wrapper mt-5">
                               <div className="text-center">
                                   <img id="logo-bottom" src={logoBottom} alt="Logo"/>
                               </div>
                           </div>
                           }
                       </div>

                   </div>
               </div>
           </div>

        );
    }
}

export default RemoveUsers;