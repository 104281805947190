import React, {Component} from 'react';
import logo from './images/logo.png';

class SplashScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundColor: '#3d3d3d',
        };

    }

    componentDidMount() {
        document.body.style.backgroundColor = this.state.backgroundColor;
    }

    render() {

        return (

            <div className="container-fluid d-flex align-items-center justify-content-center vh-100">
                <div className="row">
                    <div className="content col-12 text-center">
                        <img id="logo-bottom" src={logo} alt="Logo"/>
                    </div>
                </div>
            </div>

        );
    }
}

export default SplashScreen;
