import React, {Component} from 'react';
import axios from "axios";

import SplashScreen from './SplashScreen';
import Home from './Home';
import Setup from './Setup';
import Admin from './Admin';
import Leads from './Leads';
import LogSystem from './LogSystem';
import Upload from './Upload';
import Users from './Users';
import ResetPassword from './ResetPassword';
import RescanImages from './RescanImages';

/*
 Change Password bliver pt. ikke brugt men er beholdt til evt. senere brug
 */
import ChangePassword from './ChangePassword';

import RemoveUsers from './RemoveUsers';
import './App.css';

/*
 domain: 'https://event.tekhus.dk',
 domain: 'http://localhost:8888',
 domain: 'https://phpstack-1221684-4521041.cloudwaysapps.com',
 domain: 'https://tekhus.joch.dk',
 */

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: 'https://event.tekhus.dk',
            connected: false,
            eventId: '',
            event: {},
            eventActive: 1,
            companyId: '',
            companyName: '',
            exportKey: '',
            initials: '',
            email: '',
            token: '',
            hostUrl: '',
            colors: {
                color_1: '#FFFFFF',
                color_2: '#333333',
                color_3: '#fe5b5b',
            },
            background: {},
            logoTop: '',
            logoBottom: '',
            screen: 'splashscreen',
            appMessage: '',
            activeUsersList: {},
            companyAppLicenses: 0,
            eventFirstLeadId: 0,
            connectionError: false,
        };
    };

    componentDidMount() {

        this.splashscreenWait = setTimeout(function () {
            clearInterval(this.splashscreenWait);
            this.setup();
        }.bind(this), 500);

        setTimeout(function () {
            this.connectionTest();
        }.bind(this), 5000);

    };

    setup() {

        const companyId = this.getParameterByName('companyId');
        if(companyId) {
            this.setState({ companyId: companyId });
        } else {
            this.setState({ companyId: '' });
        }

        const email = this.getParameterByName('email');
        if(email) {
            this.setState({ email: email });
        } else {
            this.setState({ email: '' });
        }

        const eventId = this.getParameterByName('eventId');
        if(eventId) {
            this.setState({ eventId: eventId });
            this.getAppStyles(eventId, companyId);
        } else {
            this.setState({ screen: 'setup'});
        }
    }


    getParameterByName(name, url) {
        if (!url) url = window.location.href;
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    getAppStyles(eventId, companyId) {

        var data                = {};
        data.eventId            = eventId;
        data.companyId          = companyId;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.state.domain + '/core/connect.php?action=getStyles',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            this.connectionError(false);

            if(responseObject.data.hasOwnProperty('company_name')) {
                this.setState({companyName: responseObject.data.company_name});
            }

            if(responseObject.data.hasOwnProperty('event_id')) {
                this.setAppStyles(responseObject.data);
            }

            this.setState({ screen: 'setup'});



        }).catch((error) => {

            console.log(error);
            this.connectionError(true);

        });

    }

    setAppStyles(event) {

        this.setState({ colors: {
            color_1: event.event_color_1,
            color_2: event.event_color_2,
            color_3: event.event_color_3,
            }
        });

        document.body.style.backgroundColor = event.event_background_color;

        if(event.event_logo_top !== '') {
            this.setState({logoTop: "data:image/png;base64," + event.event_logo_top});
        } else {
            this.setState({logoTop: ""});
        }

        if(event.event_logo_bottom !== '') {
            this.setState({logoBottom: "data:image/png;base64," + event.event_logo_bottom});
        } else {
            this.setState({logoBottom: ""});
        }

    }

    changeScreen(screen, prop) {

        if(prop && prop.hasOwnProperty('appMessage')) {
            this.setState({ appMessage: prop.appMessage });
        } else {
            this.setState({ appMessage: '' });
        }

        if(screen === 'setup') {

            if (prop === 'clearEvent') {
                this.setState({eventId: ''});
                this.setState({
                    colors: {
                        color_1: '#FFFFFF',
                        color_2: '#9ac43b',
                        color_3: '#393a3a',
                    }
                });
            }

            document.body.style.backgroundColor = '#3d3d3d';

            this.setup();

        } else if(screen === 'removeusers') {

            this.setState({ companyName: prop.companyName });
            this.setState({ initials: prop.initials })
            this.setState({ token: prop.token });

            this.setState({ activeUsersList: prop.activeUsersList });
            this.setState({ companyAppLicenses: prop.companyAppLicenses });
            this.setState({ screen: screen });

        } else {
            this.setState({ screen: screen });
        }

    }

    startScan(data) {

        this.setState({ appMessage: '' });

        this.setState({ eventId: data.eventId });
        this.setState({ event: data.event });
        this.setState({ eventActive: parseFloat(data.event.event_active) });
        this.setState({ eventFirstLeadId: parseFloat(data.event.event_first_lead_id) });
        this.setState({ companyId: data.companyId });
        this.setState({ companyName: data.companyName });
        this.setState({ exportKey: data.exportKey });
        this.setState({ initials: data.initials })
        this.setState({ token: data.token });

        this.setAppStyles(data.event);

        this.setState({ screen: 'home'});

    }

    setAdmin(eventId, data) {
        const event = data.event;
        const token = data.token;

        const newState = {
            companyId: 1,
            companyName: 'Admin',
            exportKey: data.exportKey,
            eventId: eventId,
            token: token,
            screen: 'admin', // Dette vil blive opdateret, når alle de andre værdier er sat
        };

        if (event !== null) {
            this.setAppStyles(event);
            newState.event = event;
            newState.eventActive = parseFloat(event.event_active);
            newState.eventFirstLeadId = parseFloat(event.event_first_lead_id);
        } else {
            newState.colors = {
                color_1: '#FFFFFF',
                color_2: '#9ac43b',
                color_3: '#393a3a',
            };
        }

        // Sæt alle state-værdier på én gang og skift derefter skærmen til 'admin'
        this.setState(newState, () => {
            console.log(event);
        });
    }


    connectionTest(){

        var data                = {};

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.state.domain + '/core/connect.php',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            this.connectionError(false);

        }).catch((error) => {

            console.log(error);
            this.connectionError(true);

        });

        setTimeout(function () {
            this.connectionTest();
        }.bind(this), 60000);

    }

    connectionError(status) {
        this.setState({ connectionError: status });
    }

    render() {

      return (
          <div id="app-window" className="full-height">

              {this.state.connectionError &&
                <div id="connection-message">Could not connect to the server</div>
              }

              {this.state.appMessage !== '' &&
                <div id="connection-message">{ this.state.appMessage }</div>
              }

              {this.state.screen === 'splashscreen' && (
                  <SplashScreen
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      mainStyle           = {this.state.mainStyle}
                      />
              )}
              {this.state.screen === 'home' && (
                  <Home
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      logoTop             = {this.state.logoTop}
                      logoBottom          = {this.state.logoBottom}
                      domain              = {this.state.domain}
                      token               = {this.state.token}
                      eventId             = {this.state.eventId}
                      eventActive         = {this.state.eventActive}
                      companyName         = {this.state.companyName}
                      companyId           = {this.state.companyId}
                      eventFirstLeadId    = {this.state.eventFirstLeadId}
                      initials            = {this.state.initials}
                      changeScreen        = {this.changeScreen.bind(this)}
                      connectionError     = {this.connectionError.bind(this)}
                      />
              )}
              {this.state.screen === 'leads' && (
                  <Leads
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      logoTop             = {this.state.logoTop}
                      logoBottom          = {this.state.logoBottom}
                      domain              = {this.state.domain}
                      token               = {this.state.token}
                      eventId             = {this.state.eventId}
                      companyName         = {this.state.companyName}
                      exportKey           = {this.state.exportKey}
                      companyId           = {this.state.companyId}
                      initials            = {this.state.initials}
                      changeScreen        = {this.changeScreen.bind(this)}
                      connectionError     = {this.connectionError.bind(this)}
                      />
              )}

              {this.state.screen === 'logsystem' && (
                  <LogSystem
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      logoTop             = {this.state.logoTop}
                      logoBottom          = {this.state.logoBottom}
                      domain              = {this.state.domain}
                      token               = {this.state.token}
                      eventId             = {this.state.eventId}
                      companyName         = {this.state.companyName}
                      exportKey           = {this.state.exportKey}
                      companyId           = {this.state.companyId}
                      initials            = {this.state.initials}
                      changeScreen        = {this.changeScreen.bind(this)}
                      connectionError     = {this.connectionError.bind(this)}
                  />
              )}

              {this.state.screen === 'upload' && (
                  <Upload
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      logoTop             = {this.state.logoTop}
                      logoBottom          = {this.state.logoBottom}
                      domain              = {this.state.domain}
                      token               = {this.state.token}
                      eventId             = {this.state.eventId}
                      companyName         = {this.state.companyName}
                      exportKey           = {this.state.exportKey}
                      companyId           = {this.state.companyId}
                      initials            = {this.state.initials}
                      changeScreen        = {this.changeScreen.bind(this)}
                      connectionError     = {this.connectionError.bind(this)}
                      />
              )}
              {this.state.screen === 'setup' && (
                  <Setup
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      screen              = {this.state.screen}
                      domain              = {this.state.domain}
                      token               = {this.state.token}
                      eventId             = {this.state.eventId}
                      logoTop             = {this.state.logoTop}
                      logoBottom          = {this.state.logoBottom}
                      companyId           = {this.state.companyId}
                      companyName         = {this.state.companyName}
                      initials            = {this.state.initials}
                      email               = {this.state.email}
                      appMessage          = {this.state.appMessage}
                      changeScreen        = {this.changeScreen.bind(this)}
                      startScan           = {this.startScan.bind(this)}
                      setAdmin            = {this.setAdmin.bind(this)}
                      connectionError     = {this.connectionError.bind(this)}
                      />
              )}
              {this.state.screen === 'admin' && (
                  <Admin
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      domain              = {this.state.domain}
                      token               = {this.state.token}
                      eventId             = {this.state.eventId}
                      event               = {this.state.event}
                      eventActive         = {this.state.eventActive}
                      logoTop             = {this.state.logoTop}
                      logoBottom          = {this.state.logoBottom}
                      companyName         = {this.state.companyName}
                      companyId           = {this.state.companyId}
                      initials            = {this.state.initials}
                      changeScreen        = {this.changeScreen.bind(this)}
                      setAdmin            = {this.setAdmin.bind(this)}
                      connectionError     = {this.connectionError.bind(this)}
                      />
              )}

              {this.state.screen === 'resetpassword' && (
                  <ResetPassword
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      domain              = {this.state.domain}
                      token               = {this.state.token}
                      eventId             = {this.state.eventId}
                      event               = {this.state.event}
                      logoTop             = {this.state.logoTop}
                      logoBottom          = {this.state.logoBottom}
                      companyName         = {this.state.companyName}
                      companyId           = {this.state.companyId}
                      changeScreen        = {this.changeScreen.bind(this)}
                      connectionError     = {this.connectionError.bind(this)}
                      setAdmin            = {this.setAdmin.bind(this)}
                      />
              )}

              {this.state.screen === 'changepassword' && (
                  <ChangePassword
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      domain              = {this.state.domain}
                      token               = {this.state.token}
                      eventId             = {this.state.eventId}
                      event               = {this.state.event}
                      logoTop             = {this.state.logoTop}
                      logoBottom          = {this.state.logoBottom}
                      companyName         = {this.state.companyName}
                      companyId           = {this.state.companyId}
                      changeScreen        = {this.changeScreen.bind(this)}
                      connectionError     = {this.connectionError.bind(this)}
                      setAdmin            = {this.setAdmin.bind(this)}
                      />
              )}

              {this.state.screen === 'users' && (
                  <Users
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      logoTop             = {this.state.logoTop}
                      logoBottom          = {this.state.logoBottom}
                      domain              = {this.state.domain}
                      token               = {this.state.token}
                      eventId             = {this.state.eventId}
                      companyName         = {this.state.companyName}
                      exportKey           = {this.state.exportKey}
                      companyId           = {this.state.companyId}
                      initials            = {this.state.initials}
                      changeScreen        = {this.changeScreen.bind(this)}
                      connectionError     = {this.connectionError.bind(this)}
                      />
              )}

              {this.state.screen === 'removeusers' && (
                  <RemoveUsers
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      domain              = {this.state.domain}
                      token               = {this.state.token}
                      eventId             = {this.state.eventId}
                      event               = {this.state.event}
                      logoTop             = {this.state.logoTop}
                      logoBottom          = {this.state.logoBottom}
                      companyName         = {this.state.companyName}
                      companyId           = {this.state.companyId}
                      initials            = {this.state.initials}
                      activeUsersList     = {this.state.activeUsersList}
                      companyAppLicenses  = {this.state.companyAppLicenses}
                      changeScreen        = {this.changeScreen.bind(this)}
                      connectionError     = {this.connectionError.bind(this)}
                      setAdmin            = {this.setAdmin.bind(this)}
                      />
              )}
              {this.state.screen === 'rescanimages' && (
                  <RescanImages
                      path                = "/"
                      hostUrl             = {this.state.hostUrl}
                      colors              = {this.state.colors}
                      logoTop             = {this.state.logoTop}
                      logoBottom          = {this.state.logoBottom}
                      domain              = {this.state.domain}
                      token               = {this.state.token}
                      eventId             = {this.state.eventId}
                      companyName         = {this.state.companyName}
                      companyId           = {this.state.companyId}
                      initials            = {this.state.initials}
                      changeScreen        = {this.changeScreen.bind(this)}
                      connectionError     = {this.connectionError.bind(this)}
                      />
              )}

          </div>
          );
      };

}

export default App;
