import React, { Component } from 'react';
import axios from "axios";

import QrReader from 'modern-react-qr-reader'

import { IoIosSettings } from 'react-icons/io';
import { IoMdArrowRoundForward } from 'react-icons/io';



class RescanImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delay: 300,
            resolution: 1000,
            result: '',
            qrRead: false,
            qrCodeImage: '',
            qrReader: 1,
            step: 1,
            companyId: '',
            companyGroupId: '',
            companyName: '',
            eventId: '',
            lead: '',
            note: '',
            message: 'Scan QR Code',
            displayErrorBtn: false,
            displaySignInBtn: false,
            errorImageList: {},
            selectedImageName: 'test',
            rescanImagePath: '',
            rescanImage: '',
            rescanUserToken: '',
        };

        this.handleError        = this.handleError.bind(this);
        this.handleScan         = this.handleScan.bind(this);
        this.handleInputChange  = this.handleInputChange.bind(this)
        this.textareaRef        = React.createRef();

    }

    componentDidMount() {
        document.getElementById("app-window").focus();
        this.getImageList();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.step === 2) {
            this.textareaRef.current.focus();
        }
    }

    handleInputChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({ [itemName]: itemValue});
    }


    handleScan = lead => {
        if (lead) {
            this.setState({step: 2});
            this.setState({result: lead});
            this.setState({lead: lead});

            //this.postLead(); // Test loop
        }
    }

    handleError(err){
        console.error(err)
    }

    postImageErrorLead() {

        this.setState({ displayErrorBtn: false });

        var data                = {};
        data.image_path          = this.state.rescanImagePath;
        data.lead               = this.state.lead;
        data.company_id         = this.state.companyId;
        data.company_group_id   = this.state.companyGroupId;
        data.token              = this.props.token;
        data.rescan_user_token  = this.state.rescanUserToken;
        data.event_id           = this.props.eventId;

        this.setState({message: 'Sending Lead'});
        this.setState({step: 4});

        console.log(data);

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=postImageErrorLead',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            console.log(responseObject)

            if(responseObject.data.success === true) {

                this.props.connectionError(false);
                this.setState({message: 'Lead has been registered'});

                this.wait = setTimeout(function () {

                    clearInterval(this.wait);
                    this.setState({message: ''});

                    this.setState({note: ''});
                    this.setState({step: 1});

                    this.setState({ rescanImagePath: '' });
                    this.setState({ rescanImage: '' });

                    this.getImageList();



                }.bind(this), 2000); // Værdi skal være 2000

            } else {

                this.setState({message: responseObject.data.result});
                this.setState({ displaySignInBtn: true });

            }

        }).catch((error) => {

            console.log(error);

            this.props.connectionError(true);
            this.setState({message: 'ERROR: Lead has NOT been registered. This may be due to a lack of internet connection!'});
            this.setState({ displayErrorBtn: true });

        });

    }

    dismissLead(){

        this.setState({message: 'Lead has not been added'});
        this.setState({step: 0});

        this.wait = setTimeout(function () {

            clearInterval(this.wait);
            this.setState({note: ''});
            this.setState({step: 1});
            this.setState({message: ''});

        }.bind(this), 1000);

    }

    admin() {
        this.props.changeScreen('admin')
    }

    getImageList(){

        var data            = {};
        data.lead           = this.state.lead;
        data.note           = this.state.note;
        data.token          = this.props.token;
        data.event_id       = this.props.eventId;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=getErrorImagesList',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            console.log(responseObject);



            this.setState({ errorImageList: responseObject.data });



        }).catch((error) => {

            console.log(error);
            this.props.connectionError(true);


        });

    }

    selectImage(key) {


        const image = this.state.errorImageList[key];

        this.setState({ selectedImageName: image });

        this.getErrorImage(image);
    }

    getErrorImage(image) {

        this.setState({ rescanImagePath: image });

        const imageArray    = image.split('_');
        const eventId           = imageArray[0];
        const companyId         = imageArray[1];
        const rescanUserToken   = imageArray[2];

        var data                    = {};
        data.image                  = image;
        data.company_id             = companyId;
        data.token                  = this.props.token;

        console.log(data);

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=getErrorImage',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            this.setState({ eventId: eventId });
            this.setState({ companyId: companyId });
            this.setState({ rescanUserToken: rescanUserToken });
            this.setState({ companyGroupId: responseObject.data.company.company_group_id });
            this.setState({ companyName: responseObject.data.company.company_name });
            this.setState({ rescanImage: responseObject.data.image});

        }).catch((error) => {

            console.log(error);
            this.props.connectionError(true);


        });

    }

    render() {

        const {companyId, companyName, initials, colors, logoTop, logoBottom } = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const userStyle    = {
            color: colors.color_1 + 'BB'
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            cursor: 'pointer',
        }



        return (

            <div className="container-fluid d-flex align-items-center vh-100">
                <div className="row d-flex justify-content-center vh-100 vw-100">
                    <div className="content-wrapper page-home col-12 col col-sm-8 col-lg-6">

                        {logoTop !== '' &&
                        <div className="section-top-wrapper">
                            <img id="logo" src={logoTop} alt="Logo"/>
                        </div>
                        }

                        <div className="content pt-2">
                            {this.state.message !== "" &&
                                <div id="message" className="mt-3 mb-3 text-center" style={frontStyle} dangerouslySetInnerHTML={{__html: this.state.message}}>

                                </div>
                            }

                            {this.state.displayErrorBtn &&
                                <div className="text-center">
                                    <button className="btn-medium m-2" type="button" style={btnStyle} onClick={() => this.postImageErrorLead()}>
                                        SEND AGAIN <IoMdArrowRoundForward/>
                                    </button>
                                </div>
                            }

                            {this.state.displaySignInBtn &&
                            <div className="text-center">
                                <button className="btn-medium m-2" type="button" style={btnStyle} onClick={() => this.props.changeScreen('setup', '')}>
                                    SIGN IN <IoMdArrowRoundForward/>
                                </button>
                            </div>
                            }

                           {this.state.qrReader === 1 && this.state.step === 1 &&
                               <div id="qr-wrapper" className="col-12 text-center">
                                   <div id="qr-container">
                                       <div id="qr-reader">

                                           <QrReader
                                               delay            = {this.state.delay}
                                               resolution       = {this.state.resolution}
                                               onError          = {this.handleError}
                                               onScan           = {this.handleScan}
                                               style            = {{ width: '100%' }}
                                               showViewFinder   = {false}
                                               />

                                           <p style={frontStyle}>{this.state.lead}</p>
                                       </div>
                                   </div>
                               </div>
                           }

                            {this.state.step === 2 &&
                                <div>
                                    <div className="mb-2" style={frontStyle}>Lead: { this.state.lead }</div>
                                    <div>
                                        <textarea
                                            className           = {"form-control pl-3"}
                                            rows                = "5"
                                            id                  = "note"
                                            name                = "note"
                                            placeholder         = "note"
                                            defaultValue        = ""
                                            onChange            = {this.handleInputChange}
                                            ref                 = {this.textareaRef}
                                            />
                                    </div>

                                    <div className="text-center mt-3">
                                        <button className="btn-medium m-2" type="button" style={btnStyle} onClick={() => this.dismissLead()}>
                                            DISMISS <IoMdArrowRoundForward/>
                                        </button>
                                        <button className="btn-medium m-2" type="button" style={btnStyle} onClick={() => this.postImageErrorLead()}>
                                            SAVE <IoMdArrowRoundForward/>
                                        </button>
                                    </div>
                                </div>
                            }

                        </div>


                        {(this.state.rescanImage !== '' && this.state.step === 1) &&

                            <div>
                                <div>{ this.state.eventId }</div>
                                <div>{ this.state.companyId }</div>
                                <div>{ this.state.companyName }</div>
                                <img src={this.state.rescanImage} alt="Rescan Lead" width="600" />
                                <div className="mb-2">
                                    <strong>{ this.state.selectedImageName }</strong>
                                </div>
                            </div>
                        }

                        {(this.state.step === 1) &&
                            <div>

                                {Object.keys(this.state.errorImageList).map(key => (
                                    <div key={key} onClick={() => this.selectImage(key)}>
                                        { this.state.errorImageList[key] }
                                    </div>
                                ))}

                            </div>
                        }

                       <div className="section-bottom-wrapper mt-2">

                           {this.state.step === 1 &&
                               <div className="form-group text-center" style={userStyle}>

                                   <div id="user">
                                       <div>SIGNED IN AS</div>
                                       <div>Company: {companyName}</div>

                                       {initials !== '' &&
                                       <div>Initials: {initials}</div>
                                       }
                                   </div>

                                   {parseFloat(companyId) === 1 &&
                                       <button className="btn-medium m-2" type="button" style={btnStyle}
                                               onClick={() => this.admin()}>
                                           Admin <IoIosSettings/>
                                       </button>
                                   }

                                   {parseFloat(companyId) !== 1 &&
                                       <button className="btn-medium m-2" type="button" style={btnStyle}
                                               onClick={() => this.admin()}>
                                           Menu <IoIosSettings/>
                                       </button>
                                   }


                               </div>
                           }

                           {logoBottom !== '' &&
                           <div className="section-bottom-wrapper mt-5">
                               <div className="text-center">
                                   <img id="logo-bottom" src={logoBottom} alt="Logo"/>
                               </div>
                           </div>
                           }
                       </div>

                   </div>
               </div>
           </div>

        );
    }
}

export default RescanImages;