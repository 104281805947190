import React, { Component } from 'react';
import axios from "axios";

import { IoMdDownload } from 'react-icons/io';
import { IoIosSettings } from 'react-icons/io';
import { IoMdArrowRoundForward } from 'react-icons/io';
import { FaEdit } from 'react-icons/fa';

class Leads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            leads: [],
            editNote: null,
            newNoteValue: null,
            originalNoteValue: null,
        };

        this.handleNoteChange  = this.handleNoteChange.bind(this);


    }

    componentDidMount() {
        document.getElementById("app-window").focus();
        this.getLeads();
    }

    getLeads() {

        var data            = {};

        data.event_id       = this.props.eventId;
        data.token          = this.props.token;

        this.setState({message: 'Receiving Leads'});

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=getLeads',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            if(responseObject.data.valid_token === true) {

                this.setState({ message: '' });
                this.setState({ leads: responseObject.data.leads });
                this.props.connectionError(false);

            } else {
                var prop        = {};
                prop.appMessage = responseObject.data.result;
                this.props.changeScreen('setup', prop);

            }

        }).catch((error) => {

            console.log(error)
            this.props.connectionError(true);

        });

    }

    signOut(){

        var data          = {};
        data.token        = this.props.token;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=signOut',
            data: JSON.stringify(data)

        }).then((responseObject) => {

            this.props.changeScreen('setup', '');
            this.props.connectionError(false);

        }).catch((error) => {

            console.log(error);
            this.props.connectionError(true);

        });


    }

    export() {

        window.open(this.props.domain + "/export/?eventId=" + this.props.eventId + "&companyId=" + this.props.companyId + "&key=" + this.props.exportKey, "_blank");
    }

    handleNoteFocus(index) {
        // Store the original value before editing
        this.setState({ editNote: index });
        this.setState({ originalNoteValue: this.state.leads[index].note });
        this.setState({ newNoteValue: this.state.leads[index].note });
        this.setState({ message: ''} );

    }

    handleNoteChange(e) {
        const itemValue = e.target.value;
        this.setState({newNoteValue: itemValue});
    }

    updateNote() {

        const isConfirmed = window.confirm('Do you want to update the note?');

        if (isConfirmed) {

            const index                 = this.state.editNote;

            var data                = {};

            data.id                     = this.state.leads[index].id;
            data.eventId                = this.props.eventId;
            data.note                   = this.state.newNoteValue;
            data.token                  = this.props.token;

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.props.domain + '/core/connect.php?action=updateLeadNote',
                data: JSON.stringify(data)

            }).then((responseObject) => {

                if(responseObject.data.valid_token === true) {

                    this.setState({ message: responseObject.data.status.message});

                    this.props.connectionError(false);

                    this.setState((prevState) => {

                        const updatedLeads = [...prevState.leads];

                        updatedLeads[index] = {
                            ...updatedLeads[index],
                            note: data.note
                        };

                        return { leads: updatedLeads };
                    });

                    this.setState({ editNote: null });

                } else {

                    console.log("fail");

                }

            }).catch((error) => {

                this.setState({ message: error});
                this.props.connectionError(true);
                console.log(error)

            });

        } else {
            // Action was canceled

            this.setState({ message: 'Update action canceled'});

        }

    }

    cancelNote() {
        this.setState({
            message: '',
            editNote: null
        })
    }

    render() {

        const {companyName, initials, colors, logoTop, logoBottom } = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const userStyle    = {
            color: colors.color_1 + 'BB'
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            cursor: 'pointer',
        }

        const leadStyle = {
            padding: '5px 8px',
            border: '1px solid' + colors.color_1 + 'BB',
            marginBottom: '5px',
            borderRadius: '10px'
        }

        return (

            <div className="container-fluid d-flex align-items-center vh-100">
                <div className="row d-flex justify-content-center vh-100 vw-100">
                    <div className="content-wrapper page-home col-12 col col-sm-8 col-lg-6">

                        {logoTop !== '' &&
                        <div className="section-top-wrapper">
                            <img id="logo" src={logoTop} alt="Logo"/>
                        </div>
                        }

                        <div className="mt-3 mb-3" style={frontStyle}><strong>Leads</strong></div>

                        <div className="content">

                            {this.state.message !== "" &&
                                <div id="message" className="mb-3 text-center" style={frontStyle} dangerouslySetInnerHTML={{__html: this.state.message}}></div>
                            }

                            <div className="form-group text-center mb-4">

                                <button className="btn-medium m-1" type="button" style={btnStyle} onClick={() => this.props.changeScreen('admin', '')}>
                                    Back <IoIosSettings/>
                                </button>

                                {this.state.leads.length > 0 &&
                                    <button className="btn-medium m-1" type="button" style={btnStyle} onClick={() => this.export()}>
                                        Export <IoMdDownload/>
                                    </button>
                                }

                            </div>

                            <div>
                                {this.state.leads.map((item, index) => (
                                    <div className="row-lead" key={index} style={leadStyle} >
                                        <div><span className="lead-created">{ item.created }</span><span className="lead-initials">{ item.initials }</span></div>
                                        <div> { item.first_name } { item.last_name }</div>


                                        {this.state.editNote !== index &&
                                            <div className="edit-note" onClick={() => this.handleNoteFocus(index)}>
                                                <FaEdit />&nbsp;
                                                <span>{ item.note }</span>
                                            </div>
                                        }


                                        {this.state.editNote === index &&
                                            <div>
                                                <div className="pt-2">
                                                    <textarea
                                                        className       = {"form-control"}
                                                        rows            = "5"
                                                        id              = "note"
                                                        name            = "note"
                                                        placeholder     = "note"
                                                        defaultValue    = {item.note}
                                                        onChange        = {this.handleNoteChange}
                                                    />
                                                </div>
                                                <div className="text-center mt-1">
                                                    <button className="btn-medium m-1" type="button" style={btnStyle}
                                                            onClick={() => this.cancelNote()}>
                                                        CANCEL
                                                    </button>

                                                    <button className="btn-medium m-1" type="button" style={btnStyle}
                                                            onClick={() => this.updateNote()}>
                                                        SAVE <IoMdArrowRoundForward/>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                ))}
                            </div>

                        </div>

                        <div className="section-bottom-wrapper mt-3">
                            <div className="form-group text-center" style={userStyle}>

                                <div id="user">
                                   <div>SIGNED IN AS</div>
                                   <div>Company: {companyName}</div>

                                   {initials !== '' &&
                                   <div>Initials: {initials}</div>
                                   }
                               </div>

                           </div>

                           {logoBottom !== '' &&
                           <div className="section-bottom-wrapper mt-5">
                               <div className="text-center">
                                   <img id="logo-bottom" src={logoBottom} alt="Logo"/>
                               </div>
                           </div>
                           }
                       </div>

                   </div>
               </div>
           </div>

        );
    }
}

export default Leads;