import React, { Component } from 'react';
import axios from "axios";

import { IoMdLogIn } from 'react-icons/io';

/*
    Denne funktion bliver pt. ikke brugt men er beholdt til evt. senere brug
 */

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            currentPassword: '',
            newPassword1: '',
            newPassword2: '',
            step: 0,
        };

        this.handleSubmit       = this.handleSubmit.bind(this);
        this.handleInputChange  = this.handleInputChange.bind(this);

    }

    componentDidMount() {
        document.getElementById("app-window").focus();

    }

    handleInputChange(e) {
        const itemName = e.target.name;
        const itemValue = e.target.value;

        this.setState({ [itemName]: itemValue});
    }

    handleSubmit(e) {

        var myDiv = document.getElementById('app-window');
        myDiv.scrollTop = 0;
        this.setState({ message: ''});

        e.preventDefault();

        if(this.state.currentPassword === '') {

            this.setState({message: 'Please enter the current password'});


        } else if(this.state.newPassword1 === '') {

            this.setState({ message: 'Please enter a new password' });

        } else if(this.state.newPassword2 === '') {

            this.setState({ message: 'Please repeate the new password' });

        } else if(this.state.newPassword1 !== this.state.newPassword2) {

            this.setState({ message: 'Password 1 and Password 2 are not identical' });

        } else {

            this.setState({ step: 1 });

            var data = {};

            data.token              = this.props.token;
            data.companyId          = this.props.companyId;
            data.currentPassword    = this.state.currentPassword;
            data.newPassword1       = this.state.newPassword1;
            data.newPassword2       = this.state.newPassword2;

            console.log(data);

            axios({
                method: 'post',
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                url: this.props.domain + '/core/connect.php?action=changePassword',
                data: JSON.stringify(data)

            }).then((responseObject) => {

                console.log(responseObject);
                this.props.connectionError(false);
                this.setState({ message: responseObject.data.message })

                if(!responseObject.data.status) {
                    this.setState({ step: 0 });
                }


            }).catch((error) => {
                this.props.connectionError(true);
                console.log(error)

            });
        }

    }

    goBack(){
        this.props.changeScreen('admin', '');
    }



    render() {

        const {colors, logoTop, logoBottom } = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const messageStyle = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap",
            border: '1px solid ' + colors.color_1,
            borderRadius: '10px',
            padding: '10px'
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            cursor: 'pointer',
        }



        return (

            <form onSubmit={this.handleSubmit} className="form-post-password">
                <div className="container-fluid d-flex align-items-center vh-100">
                    <div className="row d-flex justify-content-center vh-100 vw-100">
                        <div className="content-wrapper page-home col-12 col col-sm-8 col-lg-6">

                            {logoTop !== '' &&
                            <div className="section-top-wrapper">
                                <img id="logo" src={logoTop} alt="Logo"/>
                            </div>
                            }

                            <div className="mt-3 mb-3" style={frontStyle}><strong>Change Password</strong></div>

                            <div className="content">

                                {this.state.message !== "" &&
                                    <div id="message" className="mb-3 text-center" style={messageStyle} dangerouslySetInnerHTML={{__html: this.state.message}}></div>
                                }

                                <div>

                                    {this.state.step === 0 &&
                                        <div>
                                            <div>
                                                <div className="mb-1" style={frontStyle}>Current Password</div>
                                                <div className="mb-2">
                                                    <input
                                                        className       = {"form-control pl-3"}
                                                        type            = "text"
                                                        id              = "currentPassword"
                                                        name            = "currentPassword"
                                                        placeholder     = "Current Password"
                                                        defaultValue    = ''
                                                        onChange        = {this.handleInputChange}
                                                        />
                                                </div>
                                            </div>


                                            <div className="mb-1" style={frontStyle}>New Password</div>
                                            <div className="mb-2">
                                                <input
                                                    className       = {"form-control pl-3"}
                                                    type            = "text"
                                                    id              = "newPassword1"
                                                    name            = "newPassword1"
                                                    placeholder     = "New Password"
                                                    defaultValue    = ''
                                                    onChange        = {this.handleInputChange}
                                                    />
                                            </div>

                                            <div className="mb-2">
                                                <input
                                                    className       = {"form-control pl-3"}
                                                    type            = "text"
                                                    id              = "newPassword2"
                                                    name            = "newPassword2"
                                                    placeholder     = "Repeate Password"
                                                    defaultValue    = ''
                                                    onChange        = {this.handleInputChange}
                                                    />
                                            </div>

                                        </div>
                                    }



                                    <div className="form-group text-right mt-4 mb-4">

                                        {this.state.step === 0 &&
                                            <button className="btn-medium btn-white" type="submit" style={btnStyle}>
                                                Change Password <IoMdLogIn/>
                                            </button>
                                        }

                                        <div id="go-back" style={frontStyle} onClick={() => this.goBack()}>
                                            Back <IoMdLogIn/>
                                        </div>
                                    </div>


                                </div>



                           </div>



                           {logoBottom !== '' &&
                               <div className="section-bottom-wrapper mt-5">
                                   <div className="text-center">
                                       <img id="logo-bottom" src={logoBottom} alt="Logo"/>
                                   </div>
                               </div>
                           }


                       </div>
                   </div>
               </div>
            </form>
        );
    }
}

export default ChangePassword;